
.animation-pulse {
    animation: pulse 2s infinite;
}

@keyframes pulse {
    0% {
        background-color: rgba(0, 0, 0, .05);
    }

    65% {
        background-color: rgba(0, 0, 0, .10);
    }

    100% {
        background-color: rgba(0, 0, 0, .05);
    }
}
