
@keyframes placeHolderShimmer {
    0% {
        background-position: -400px 0
    }
    100% {
        background-position: 400px 0
    }
}

.animated-background {
    animation-duration: 3s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 8%, rgba(0, 115, 230, 0.25) 18%, rgba(255, 255, 255, 0) 33%);
    background-size: 800px 400px;
}

.animated-background-offset {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 8%, rgba(170, 170, 170, 0.25) 18%, rgba(255, 255, 255, 0) 33%);
    background-size: 800px 400px;
}