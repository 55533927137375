
.collapsable {
    display: grid;
    grid-template-rows: 0fr;
    transition: 1000ms grid-template-rows ease;
}

.collapsable.expanded {
    grid-template-rows: 1fr;
}
